import React from 'react';

const CTA = () => {
  return (
    <div className="cta">
      <a href="#about" className="btn">
        More about me
      </a>
      <a href="#contact" className="btn btn-primary">
        Contact Me
      </a>
    </div>
  );
};

export default CTA;
