import React from 'react';
import './Portfolio.css';
import { data, getIcon } from '../../assets/projectData';
import SingleProject from '../singleProject/SingleProject';

const Portfolio = () => {
  return (
    <section id="portfolio">
      <div className="container portfolio__container">
        {data.map(
          ({ title, github, demo, images, frontend, backend, deployment, description }, index) => {
            return (
              <article key={index + title} className="portfolio__item">
                <div className="portfolio__item-top">
                  <SingleProject images={images} />

                  <div className="portfolio-item-technologies">
                    <h3>
                      <strong>FRONTEND</strong>
                    </h3>
                    <div className="technologies">
                      {frontend.map((element, index) => getIcon(element, index))}
                    </div>
                    <h3>
                      <strong>BACKEND</strong>
                    </h3>
                    <div className="technologies">
                      {backend.map((element, index) => getIcon(element, index))}
                    </div>
                    <h3>
                      <strong>DEPLOYMENT</strong>
                    </h3>
                    <div className="technologies">
                      {deployment.map((element, index) => getIcon(element, index))}
                    </div>
                  </div>
                </div>
                <h2>{title}</h2>
                <div className="portfolio__item-description">
                  <h3>
                    <strong>Description:</strong>
                  </h3>
                  <p>{description}</p>
                </div>
                <div className="portfolio__item-cta">
                  <a href={github} className="btn" target="_blank" rel="noreferrer">
                    Github
                  </a>
                  <a href={demo} className="btn btn-primary" target="_blank" rel="noreferrer">
                    Live Demo
                  </a>
                </div>
              </article>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Portfolio;
