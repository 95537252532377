import './SingleProject.css';
import { useState } from 'react';

const SingleProject = ({ images }) => {
  const [activePanel, setActivePanel] = useState(0); // Default to first picture as active

  const handleClick = (index) => {
    setActivePanel(index); // Set the clicked panel as active
  };

  return (
    <div className="externalSingleProjectContainer">
      <div className="spContainer">
        {images.map((picture, index) => (
          <div
            key={index}
            className={`panel ${activePanel === index ? 'active' : ''}`}
            onClick={() => handleClick(index)}>
            {activePanel === index ? (
              <img className="project-image" src={picture} alt={`project ${index}`} />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleProject;
