import React from 'react';
import './Contact.css';
import { HiOutlineMail } from 'react-icons/hi';

const Contact = () => {
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <HiOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>rafal.hes@yahoo.com</h5>
            <a href="mailto:rafal.hes@yahoo.com" target="_blank" rel="noreferrer">
              Send a message
            </a>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Contact;
