import React from "react";
import "./About.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3 months of working &#40;internship&#41;</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ completed projects</small>
            </article>
          </div>
          <p>
            I am last year&apos;s graduate of engineering studies, with a
            passion for creating interactive web applications. I focus mainly on
            JavaScript and the ReactJS libraries, which is confirmed by the
            projects in my portfolio. I am constantly looking for new challenges
            and developing my skills. At the moment I am learning TypeScript. I
            am looking for an opportunity to join a team as a Junior Fullstack
            or Frontend Developer that will allow me to develop professionally.
          </p>

          <a href="#contact" className="btn btn-primary">
            Contact Me
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
