import React from 'react';
import './Footer.css';
// import logo from '../../assets/Logo/5.svg';
import logo from '../../assets/Logo/4.png';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer__logo">
        {/* <img src={logo} alt="logo" /> */}
      </div>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
