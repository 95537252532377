import car1 from './projects/car-rental-app-1.png';
import car2 from './projects/car-rental-app-2.png';
import car3 from './projects/car-rental-app-3.png';
import car4 from './projects/car-rental-app-4.png';
import car5 from './projects/car-rental-app-5.png';
import car6 from './projects/car-rental-app-6.png';
import car7 from './projects/car-rental-app-7.png';
import car8 from './projects/car-rental-app-8.png';
import car9 from './projects/car-rental-app-9.png';
import car10 from './projects/car-rental-app-10.png';
import car11 from './projects/car-rental-app-11.png';
import car12 from './projects/car-rental-app-12.png';
import car13 from './projects/car-rental-app-13.png';
import car14 from './projects/car-rental-app-14.png';
import car15 from './projects/car-rental-app-15.png';
import car16 from './projects/car-rental-app-16.png';
import car17 from './projects/car-rental-app-17.png';
import car19 from './projects/car-rental-app-19.png';
import car20 from './projects/car-rental-app-20.png';
import car21 from './projects/car-rental-app-21.png';
import car22 from './projects/car-rental-app-22.png';
import car23 from './projects/car-rental-app-23.png';
import car24 from './projects/car-rental-app-24.png';
import car25 from './projects/car-rental-app-25.png';
import pm3 from './projects/project-management-3.png';
import pm4 from './projects/project-management-4.png';
import pm7 from './projects/project-management-7.png';
import githubFinder1 from './projects/github-finder-1.png';
import githubFinder2 from './projects/github-finder-2.png';
import githubFinder3 from './projects/github-finder-3.png';
import flixx1 from './projects/flixx-1.png';
import flixx2 from './projects/flixx-2.png';
import flixx3 from './projects/flixx-3.png';
import flixx4 from './projects/flixx-4.png';
import flexibble1 from './projects/flexibble-01.png';
import flexibble2 from './projects/flexibble-02.png';
import flexibble3 from './projects/flexibble-03.png';
import flexibble4 from './projects/flexibble-04.png';
import property_1 from './projects/property_1.png';
import property_2 from './projects/property_2.png';
import property_3 from './projects/property_3.png';
import property_5 from './projects/property_5.png';
import property_6 from './projects/property_6.png';
import property_8 from './projects/property_8.png';

import { FaReact } from 'react-icons/fa';
import {
  SiApollographql,
  SiBootstrap,
  SiGraphql,
  SiAwsamplify,
  SiHeroku,
  SiNetlify,
  SiVercel
} from 'react-icons/si';
import { BiLogoNodejs, BiLogoJavascript, BiLogoMongodb, BiLogoTailwindCss } from 'react-icons/bi';
import { TbBrandNextjs } from 'react-icons/tb';

const getIcon = (iconName, index) => {
  switch (iconName) {
    case 'react':
      return <FaReact key={index} />;
    case 'apollo':
      return <SiApollographql key={index} />;
    case 'bootstrap':
      return <SiBootstrap key={index} />;
    case 'graphql':
      return <SiGraphql key={index} />;
    case 'amplify':
      return <SiAwsamplify key={index} />;
    case 'node':
      return <BiLogoNodejs key={index} />;
    case 'js':
      return <BiLogoJavascript key={index} />;
    case 'mongodb':
      return <BiLogoMongodb key={index} />;
    case 'heroku':
      return <SiHeroku key={index} />;
    case 'netlify':
      return <SiNetlify key={index} />;
    case 'nextjs':
      return <TbBrandNextjs key={index} />;
    case 'tailwind':
      return <BiLogoTailwindCss key={index} />;
    case 'vercel':
      return <SiVercel key={index} />;

    default:
      return null;
  }
};

const data = [
  // {
  //   id: 1,
  //   images: [
  //     car1,
  //     car2,
  //     car3,
  //     car4,
  //     car5,
  //     car6,
  //     car7,
  //     car8,
  //     car9,
  //     car10,
  //     car11,
  //     car12,
  //     car13,
  //     car14,
  //     car15,
  //     car16,
  //     car17,
  //     car19,
  //     car20,
  //     car21,
  //     car22,
  //     car23,
  //     car24,
  //     car25
  //   ],
  //   title: 'Car Rental App',
  //   description:
  //     'The application allows you to browse cars, filter the list depending on the selected criteria, make reservations with the option of choosing insurance and rent. The user who rented a car can leave his comment and rating under it. The system verifies whether the user is authorized to do so. In addition, the application for the showroom offers car fleet management, making changes to reservations at the customer&apos;s request, creating electronic vehicle handover cards, calculating additional costs based on the vehicle handover card and the ability to verify whether the customer has paid all costs. The application also has a user verification system by logging in.',
  //   github: 'https://github.com/Zigmall/car-sharing-app',
  //   demo: 'https://master.d270zsb2ieq6e0.amplifyapp.com',
  //   frontend: ['js', 'react', 'apollo'],
  //   backend: ['node', 'apollo', 'graphql', 'mongodb'],
  //   deployment: ['amplify']
  // },

  // {
  //   id: 2,
  //   images: [flexibble1, flexibble2, flexibble3, flexibble4],
  //   title: 'Flexibble',
  //   description:
  //     'Flexibble is a clone of the Dribbble.com platform, where designers present their work in the form of images, creating portfolio profiles. Users can share jobs, view trends, and use recruiting features. The application is built using Next.js, TypeScript and Tailwindjs. GraphQL was used for communication. Thanks to the use of the Grafbase API, the application does not need a standard database connection.',
  //   github: 'https://github.com/Zigmall/flexibble',
  //   demo: 'https://flexibble-zigmall.vercel.app',
  //   frontend: ['nextjs', 'graphql', 'tailwind'],
  //   backend: ['graphql'],
  //   deployment: ['vercel']
  // },

  {
    id: 1,
    images: [property_1, property_2, property_3, property_5, property_6, property_8],
    title: 'Rent-Base',
    description:
      "The application is built with Next.js and styled using Tailwind CSS. Is designed for searching rental properties. Users can browse rental listings, view the property's location on a map, and see photos. Additionally application also allows direct communication with property owners by messaging and user verification system through login system.",
    github: 'https://github.com/Zigmall/property-app',
    demo: 'https://rent-base.vercel.app/',
    frontend: ['nextjs', 'tailwind'],
    backend: ['nextjs'],
    deployment: ['vercel']
  },

  {
    id: 3,
    images: [flixx1, flixx2, flixx3, flixx4],
    title: 'Flixx',
    description:
      'Pure Vanilla JavaScript application for browsing movies and series. It uses The Movie Database API. It allows to search for movies and series by title and sort them by popularity. It also shows data about budget, runtime, description, average rate, genres and release date.',
    github: 'https://github.com/Zigmall/js-flixx-project',
    demo: 'https://main.d7wgjo3xix8mw.amplifyapp.com',
    frontend: ['js'],
    backend: [],
    deployment: ['amplify']
  },
  {
    id: 4,
    images: [pm7, pm3, pm4],
    title: 'Project Management',
    description:
      'SPA application for create and manage projects and add clients to them. It allows to update details (full CRUD functionality).',
    github: 'https://github.com/Zigmall/project_management',
    demo: 'https://zigmal-project-menegement-d06761ce27ad.herokuapp.com',
    frontend: ['js', 'react', 'apollo', 'bootstrap'],
    backend: ['node', 'apollo', 'graphql', 'mongodb'],
    deployment: ['heroku']
  }
  // {
  //   id: 5,
  //   images: [githubFinder1, githubFinder2, githubFinder3],
  //   title: 'Github Finder',
  //   description:
  //     'Application for searching GitHub users by name or nickname (uses GitHub API). It shows information about bio, number of repositories, cores, followers and followers of users. Additionally, at the bottom of the page, it lists the five most recently released repositories with links and a button to visit the user&apos;s GitHub page. The app uses context and hooks.',
  //   github: 'https://github.com/Zigmall/githubFinder',
  //   demo: 'https://githubfinder445.netlify.app',
  //   frontend: ['js', 'react'],
  //   backend: [],
  //   deployment: ['netlify']
  // }
  // {
  //   id: 6,
  //   images: [shoppingList1, shoppingList2, shoppingList3, shoppingList4],
  //   title: 'Shopping List',
  //   description:
  //     'Simple Vanilla JavaScript application for creating shopping list. It uses local storage.',
  //   github: 'https://github.com/Zigmall/js-list-project',
  //   demo: 'https://cool-tiramisu-d566c6.netlify.app',
  //   frontend: ['js'],
  //   backend: [],
  //   deployment: ['netlify']
  // }
];

export { data, getIcon };
