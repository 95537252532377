import React from 'react';
import Header from './component/header/Header';
import About from './component/about/About';
import Experience from './component/experience/Experience';
import Portfolio from './component/portfolio/Portfolio';
import Testimonials from './component/testimonials/Testimonials';
import Contact from './component/contact/Contact';
import Footer from './component/footer/Footer';

const App = () => {
  

  return (
    <>
      <Header />
      <About />
      <Experience />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
