import React from 'react';
import './Testimonials.css';
import AWTR1 from '../../assets/avatar1.png';


const data = [
  {
    avatar: AWTR1,
    name: 'Damian Gronczewski',
    review:
      'Rafal is person who is perfect team member. From technical point of view he is able to take and implement any task which appear in project. Rafat is also good team member eager to help other teammates in their technical tasks. He want to gain extra knowledge and I saw in project that he not only implement task but also looking for alternatives approaches. Rafal takes care also about code quality and devops topics. I could work with him in project because I see progress and I am sure he will evolve even more.'
  }
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Reviews from clients</h5>
      <h2>Testimonials</h2>

      <div
        className="container testimonials__container">
        {data.map(({ avatar, name, review }, index) => {
          return (
            <div key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Testimonials;
